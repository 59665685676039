import type { ComponentSingleStyleConfig } from "@chakra-ui/react"

import { sourceSansProFontString } from "../values"

export const FormLabel: ComponentSingleStyleConfig = {
  baseStyle: {
    color: "grey.500",
    fontFamily: sourceSansProFontString,
    fontSize: "14px",
    lineHeight: "18px",
  },
}
