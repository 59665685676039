import type { ComponentSingleStyleConfig } from "@chakra-ui/react"

export const Divider: ComponentSingleStyleConfig = {
  baseStyle: {
    borderColor: "grey.200",
  },
  defaultProps: {
    colorScheme: "primary",
  },
}
