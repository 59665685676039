import { Center } from "@chakra-ui/react"
import { useMountEffect } from "@react-hookz/web"
import { useState } from "react"
import { FormattedMessage } from "react-intl"
import { Outlet } from "react-router-dom"

import { useAccessToken } from "@/hooks/useAccessToken"
import { isAccessTokenExpired } from "@/utils/token"

import { useSendRefreshTokenRequest } from "../../networking/axios"
import { FullLoader } from "../FullLoader"

/**
 * component used to:
 * - avoid blinking before displaying the login page the first time
 * - trigger the refresh token request if the access token is expired even if no network request is made by the app,
 *   this allows to immediately redirect the user to the login page if the refresh token is expired or absent
 *
 * Note that once the refresh token request will only be triggered once, when the component is mounted
 */
export default function AuthWall() {
  const { sendRefreshTokenRequest } = useSendRefreshTokenRequest()
  const { accessToken } = useAccessToken()

  const [authWallStatus, setAuthWallStatus] = useState<"CLOSED" | "REFRESHING" | "OPEN">(() => {
    return accessToken === undefined || isAccessTokenExpired(accessToken) ? "REFRESHING" : "OPEN"
  })

  useMountEffect(() => {
    if (authWallStatus === "OPEN") return
    sendRefreshTokenRequest()
      .then(() => setAuthWallStatus("OPEN"))
      .catch(() => setAuthWallStatus("CLOSED"))
  })

  switch (authWallStatus) {
    case "REFRESHING":
      return <FullLoader />
    case "OPEN":
      return <Outlet />
    case "CLOSED":
      return (
        <Center boxSize="full" color="gray.500">
          <FormattedMessage id="error.generic.title" />
        </Center>
      )
  }
}
