import { Flex } from "@chakra-ui/react"

// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
export const Error = () => {
  return (
    <Flex height="100%" justify="center" align="center" flexDir="column" gap={5}>
      <div>Sorry, an error occurred 😥</div>
      <div>The technical team 🧑‍💻 has been informed.</div>
      <div>
        You can try to refresh this page or go to the <a href="/">home page</a>.
      </div>
    </Flex>
  )
}
