import { Text } from "@chakra-ui/react"
import type { ComponentProps, ElementType } from "react"

type TextProps<C extends ElementType> = ComponentProps<typeof Text<C>>

export const H1: React.FC<TextProps<"h1">> = ({ children, ...typoProps }) => (
  <Text textStyle="h1" as="h1" {...typoProps}>
    {children}
  </Text>
)

export const H2: React.FC<TextProps<"h2">> = ({ children, ...typoProps }) => (
  <Text textStyle="h2" as="h2" {...typoProps}>
    {children}
  </Text>
)

export const H3: React.FC<TextProps<"h3">> = ({ children, ...typoProps }) => (
  <Text textStyle="h3" as="h3" {...typoProps}>
    {children}
  </Text>
)

export const H4: React.FC<TextProps<"h4">> = ({ children, ...typoProps }) => (
  <Text textStyle="h4" as="h4" {...typoProps}>
    {children}
  </Text>
)

export const H5: React.FC<TextProps<"h4">> = ({ children, ...typoProps }) => (
  <Text textStyle="h5" as="h5" {...typoProps}>
    {children}
  </Text>
)

export const P1: React.FC<TextProps<"span">> = ({ children, ...typoProps }) => (
  <Text textStyle="p1" as="span" {...typoProps}>
    {children}
  </Text>
)

export const P1B: React.FC<TextProps<"span">> = ({ children, ...typoProps }) => (
  <P1 fontWeight="bold" {...typoProps}>
    {children}
  </P1>
)

export const P2: React.FC<TextProps<"span">> = ({ children, ...typoProps }) => (
  <Text textStyle="p2" as="span" {...typoProps}>
    {children}
  </Text>
)
export const P2B: React.FC<TextProps<"span">> = ({ children, ...typoProps }) => (
  <P2 fontWeight="bold" {...typoProps}>
    {children}
  </P2>
)

export const P3: React.FC<TextProps<"span">> = ({ children, ...typoProps }) => (
  <Text textStyle="p3" as="span" {...typoProps}>
    {children}
  </Text>
)
export const P3B: React.FC<TextProps<"span">> = ({ children, ...typoProps }) => (
  <P3 fontWeight="bold" {...typoProps}>
    {children}
  </P3>
)

export const P4: React.FC<TextProps<"span">> = ({ children, ...typoProps }) => (
  <Text textStyle="p4" as="span" {...typoProps}>
    {children}
  </Text>
)
export const P4B: React.FC<TextProps<"span">> = ({ children, ...typoProps }) => (
  <P4 fontWeight="bold" {...typoProps}>
    {children}
  </P4>
)

export const LinkL: React.FC<TextProps<"a">> = ({ children, ...typoProps }) => (
  <Text textStyle="linkL" as="a" {...typoProps}>
    {children}
  </Text>
)

export const LinkM: React.FC<TextProps<"a">> = ({ children, ...typoProps }) => (
  <Text textStyle="linkM" as="a" {...typoProps}>
    {children}
  </Text>
)
