/**
 * We need to use the multipart/form-data format to send files to the backend,
 * but a FormData only accepts strings or blobs as values.
 * To still be able to handle non string or blob values (like numbers or booleans),
 * we store them as a stringified JSON in the "json_data" key.
 * In the backend, the MultiPartDecoder will decode the json_data and merge it with the rest of the form data.
 */
export const customFormDataFn = <Body extends object>(body: Body): FormData => {
  const formData = new FormData()

  const jsonData: Record<string, unknown> = {}

  for (const key in body) {
    const value = body[key]
    if (typeof value === "string" || value instanceof Blob) {
      formData.append(key, value)
    } else if (
      Array.isArray(value) &&
      value.every(item => typeof item === "string" || item instanceof Blob)
    ) {
      for (const item of value) {
        const arrayKey = key.endsWith("[]") ? key : `${key}[]`
        formData.append(arrayKey, item as string | Blob)
      }
    } else {
      jsonData[key] = value
    }
  }

  const jsonDataString = JSON.stringify(jsonData)
  if (jsonDataString !== "{}") formData.append("json_data", jsonDataString)

  return formData
}
