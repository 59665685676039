import { Navigate, Outlet } from "react-router-dom"

import { useAuthenticatedUserContext } from "@/context/AuthenticatedUserContext"
import { PATHS } from "@/routing/paths"

export const GuruWall = () => {
  const { user } = useAuthenticatedUserContext()

  if (user?.isGuru) {
    return <Outlet />
  }

  return <Navigate to={PATHS.DASHBOARD} replace />
}
