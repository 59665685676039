import type { ComponentMultiStyleConfig } from "@chakra-ui/react"

export const Table: ComponentMultiStyleConfig = {
  parts: ["container", "th", "td", "caption"],
  variants: {
    simple: {
      td: {
        borderColor: "gray.200",
      },
    },
  },
}
