import { Suspense, lazy } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router-dom"

import { FullLoader } from "@/components/FullLoader"
import { Layout } from "@/components/Layout"
import AuthWall from "@/components/RoutingWalls/AuthWall"
import { GuruWall } from "@/components/RoutingWalls/GuruWall"

import { AUTH_PATHS, PATHS } from "./paths"

const NotFound = lazy(() => import("@/pages/NotFound"))
const Login = lazy(() => import("@/pages/Auth/Login"))
const Logout = lazy(() => import("@/pages/Auth/Logout"))
const RequestPassword = lazy(() => import("@/pages/Auth/RequestPassword"))
const RenewPassword = lazy(() => import("@/pages/Auth/RenewPassword"))
const Dashboard = lazy(() => import("@/pages/Dashboard"))
const Clients = lazy(() => import("@/pages/Clients"))
const InterBu = lazy(() => import("@/pages/InterBu"))
const Help = lazy(() => import("@/pages/Help"))
const Parameters = lazy(() => import("@/pages/Parameters"))
const Collaborators = lazy(() => import("@/pages/Collaborators"))
const CollaboratorsSheet = lazy(() => import("@/pages/CollaboratorSheet"))
const Customers = lazy(() => import("@/pages/Network/tab/People"))
const CustomerSheet = lazy(() => import("@/pages/PeopleSheet"))
const Network = lazy(() => import("@/pages/Network"))
const ClientSheet = lazy(() => import("@/pages/ClientSheet"))
const TProperties = lazy(() => import("@/pages/TProperties"))
const TpropertySheet = lazy(() => import("@/pages/TpropertySheet"))
const ProjectsOffers = lazy(() => import("@/pages/ProjectsOffers"))
const ProjectOfferSheet = lazy(() => import("@/pages/ProjectOfferSheet"))
const Admin = lazy(() => import("@/pages/Admin"))

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.AUTH + AUTH_PATHS.LOGIN} element={<Login />} />
      <Route
        path={PATHS.AUTH + AUTH_PATHS.REQUEST_PWD}
        element={
          <Suspense fallback={<FullLoader />}>
            <RequestPassword />
          </Suspense>
        }
      />
      <Route
        path={PATHS.AUTH + AUTH_PATHS.RENEW_PWD}
        element={
          <Suspense fallback={<FullLoader />}>
            <RenewPassword />
          </Suspense>
        }
      />
      <Route element={<AuthWall />}>
        <Route
          element={
            <Layout>
              <Suspense fallback={<FullLoader />}>
                <Outlet />
              </Suspense>
            </Layout>
          }
        >
          <Route path="*" element={<NotFound />} />
          <Route path={PATHS.DASHBOARD} element={<Dashboard />} />
          {/** TODO : Clean after propertyOffers validation */}
          <Route path={PATHS.CLIENTS}>
            <Route index element={<Clients />} />
            <Route path=":clientId/*" element={<ClientSheet />} />
          </Route>
          {/** END TODO */}
          <Route path={PATHS.CUSTOMERS}>
            <Route index path="*" element={<Customers parentPath={PATHS.CUSTOMERS} />} />
            <Route path=":peopleId/*" element={<CustomerSheet parentPath={PATHS.CUSTOMERS} />} />
          </Route>
          <Route path={PATHS.PROPERTIES}>
            <Route
              index
              element={<Navigate to={`${PATHS.PROPERTIES}${PATHS.PROPERTY_OFFERS}`} replace />}
            />
            {/** TODO : Clean after propertyOffers validation */}
            <Route path={`${PATHS.PROPERTIES}${PATHS.PROPERTIES_OLD}`}>
              <Route index element={<TProperties />} />
              <Route path=":propertyId/*" element={<TpropertySheet />} />
            </Route>
            {/** END TODO */}
            <Route path={`${PATHS.PROPERTIES}${PATHS.PROPERTY_OFFERS}`}>
              <Route index element={<ProjectsOffers />} />
              <Route
                path=":projectId/*"
                element={
                  <ProjectOfferSheet parentPath={`${PATHS.PROPERTIES}${PATHS.PROPERTY_OFFERS}`} />
                }
              />
            </Route>
          </Route>
          <Route path={`${PATHS.INTER_BU}/*`} element={<InterBu />} />
          <Route path={PATHS.RESOURCES} element={<Navigate to={PATHS.COLLABORATORS} replace />} />
          <Route path={PATHS.COLLABORATORS}>
            <Route index path="*" element={<Collaborators />} />
            <Route
              path=":collaboratorId/*"
              element={<CollaboratorsSheet parentPath={PATHS.COLLABORATORS} />}
            />
          </Route>
          <Route path={PATHS.HELP} element={<Help />} />
          <Route path={`${PATHS.PARAMETERS}/*`} element={<Parameters />} />

          <Route element={<GuruWall />}>
            <Route path={`${PATHS.NETWORK}/*`} element={<Network />} />
            <Route path={`${PATHS.ADMIN}/*`} element={<Admin />} />
          </Route>
        </Route>
        <Route
          path={PATHS.AUTH + AUTH_PATHS.LOGOUT}
          element={
            <Suspense fallback={<FullLoader />}>
              <Logout />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  )
}
