export const colors = {
  primary: {
    100: "#FCE3E7",
    400: "#E34C64",
    500: "#C92E47",
    600: "#A5051F",
    700: "#FCE3E7",
  },
  secondary: {
    100: "#DEF7EB",
    500: "#35B778",
    800: "#037940",
  },
  grey: {
    100: "#E8EEF6",
    200: "#D2D5DA",
    300: "#AFB3B8",
    500: "#575D66",
    600: "#3A3E44",
    900: "#202222",
  },
  yellow: {
    100: "#F5F5C8",
    500: "#FACB23",
    800: "#AC8601",
  },
}

export const sourceSansProFontString = "'Twemoji Country Flags', 'Source Sans Pro', serif"
export const sourceSerif4FontString = "'Twemoji Country Flags', 'Source Serif 4', serif"

export const fonts = {
  body: sourceSansProFontString,
  heading: sourceSansProFontString,
  mono: sourceSansProFontString,
}

export const fontWeights = {
  normal: 400,
  medium: 500,
  bold: 600,
}

export const shadows = {
  M: "0px 20px 8px rgba(33, 40, 47, 0.01), 0px 11px 7px rgba(33, 40, 47, 0.03), 0px 5px 5px rgba(33, 40, 47, 0.04), 0px 1px 3px rgba(33, 40, 47, 0.05), 0px 0px 0px rgba(33, 40, 47, 0.05)",
}

export const textStyles = {
  h1: {
    fontSize: "56px",
    lineHeight: "67px",
    fontWeight: "500",
    fontFamily: sourceSerif4FontString,
  },
  h2: {
    fontSize: "48px",
    lineHeight: "58px",
    fontWeight: "500",
    fontFamily: sourceSerif4FontString,
  },
  h3: {
    fontSize: "36px",
    lineHeight: "40px",
    fontWeight: "500",
    fontFamily: sourceSerif4FontString,
  },
  h4: {
    fontSize: "28px",
    lineHeight: "34px",
    fontWeight: "500",
    fontFamily: sourceSerif4FontString,
  },
  h5: {
    fontSize: "22px",
    lineHeight: "26px",
    fontWeight: "500",
    fontFamily: sourceSerif4FontString,
  },
  p1: {
    fontSize: "20px",
    lineHeight: "26px",
    fontWeight: "400",
    fontFamily: sourceSansProFontString,
  },
  p2: {
    fontSize: "16px",
    lineHeight: "21px",
    fontWeight: "400",
    fontFamily: sourceSansProFontString,
  },
  p3: {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "400",
    fontFamily: sourceSansProFontString,
  },
  p4: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "400",
    fontFamily: sourceSansProFontString,
  },
  linkL: {
    fontSize: "22px",
    lineHeight: "28px",
    fontWeight: "600",
    fontFamily: sourceSansProFontString,
  },
  linkM: {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "600",
    fontFamily: sourceSansProFontString,
  },
  linkS: {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "600",
    fontFamily: sourceSansProFontString,
  },
}
