import type { ComponentMultiStyleConfig } from "@chakra-ui/react"

export const Modal: ComponentMultiStyleConfig = {
  parts: ["dialogContainer", "dialog", "header", "closeButton", "body", "footer"],
  baseStyle: {
    body: {
      py: 0,
      px: 10,
    },
    footer: {
      p: 10,
      display: "flex",
      justifyContent: "center",
      gap: 10,
    },
  },
}
