import type { ComponentMultiStyleConfig } from "@chakra-ui/react"

export const Tag: ComponentMultiStyleConfig = {
  parts: ["container", "label", "closeButton", "leftIcon"],
  baseStyle: {
    label: {
      textStyle: "p4",
    },
    container: {
      verticalAlign: "middle",
    },
  },
  variants: {
    secondary: {
      container: {
        bg: "secondary.100",
        color: "secondary.500",
      },
      label: {
        color: "secondary.800",
      },
    },
    warning: {
      container: {
        bg: "yellow.100",
        color: "yellow.500",
      },
      label: {
        color: "yellow.800",
      },
    },
    deepGrey: {
      container: {
        bg: "grey",
      },
      label: {
        color: "white",
      },
    },
    red: {
      container: {
        bg: "red.600",
        color: "white",
        paddingLeft: "10px",
      },
      closeButton: {
        color: "white",
        paddingRight: "10px",
      },
    },
    autocomplete: {
      container: {
        bgColor: "grey.100",
      },
    },
    round: {
      container: {
        bg: "red.600",
        color: "white",
        rounded: "full",
      },
      label: {
        margin: "auto",
      },
    },

    default: {
      container: {
        bg: "grey.200",
        color: "grey.300",
      },
      label: {
        color: "grey.600",
      },
    },
  },
  sizes: {
    md: {
      container: {
        p: "1",
      },
    },
  },
  defaultProps: {
    size: "md",
  },
}
