import type { Merged, Prettify, UnionToIntersection, UnknownRecord } from "@/utils/types/utils"

export const isKeyInObject = <T extends Record<string | number | symbol, unknown>>(
  key: string | number | symbol,
  object: T
): key is keyof T => key in object

export const objectKeys = <T extends Record<string | number | symbol, unknown>>(
  obj: T
): (keyof T)[] => Object.keys(obj) as (keyof T)[]

export const objectValues = <T extends Record<string | number | symbol, keyof T>>(
  obj: T
): (keyof T)[] => Object.values(obj)

export const arrayGroupByKey = <T extends { [k in keyof T]: unknown }>(
  arr: T[] | undefined,
  sort_on?: keyof T & string,
  order_by?: keyof T & string,
  order?: "asc" | "desc"
): Record<string, T[]> => {
  const groups = {} as Record<string, T[]>

  if (isNullOrUndefined(arr) || arr.length === 0 || isBlank(sort_on)) return groups

  arr.map(v => {
    if (typeof v[sort_on] !== "string") return

    const group: string = v[sort_on] as string
    const g = groups[group] ?? []

    g.push(v)
    groups[group] = g
  })

  if (isNotBlank(order_by)) {
    const o = order === "asc" ? 1 : 0
    for (const [k, v] of Object.entries(groups)) {
      groups[k] = v.sort((a, b) => {
        const av = a[order_by]
        const bv = b[order_by]

        return av === bv ? 0 : (av ?? -1) > (bv ?? -1) ? o : -o
      })
    }
  }

  return groups
}

export const isNullOrUndefined = <T>(value: T | undefined | null): value is null | undefined =>
  value === undefined || value === null

export const isNotNullNorUndefined = <T>(value: T | undefined | null): value is T =>
  !isNullOrUndefined<typeof value>(value)

export const isNotBlank = (value: string | undefined | null): value is string =>
  isNotNullNorUndefined<typeof value>(value) && value !== ""

export const isBlank = (value: string | undefined | null): value is null | undefined | "" =>
  !isNotBlank(value)

export const isMoreThan0 = (value: number | undefined | null): value is number =>
  isNotNullNorUndefined(value) && value > 0

export const isNotMoreThan0 = (value: number | undefined | null): value is null | undefined | -1 =>
  !isMoreThan0(value)

export const removeFieldsFromObject = <T extends Record<string, unknown>, K extends keyof T>(
  obj: T,
  fields: K[]
): Omit<T, K> => {
  const newObj = { ...obj }
  for (const field of fields) {
    delete newObj[field]
  }

  return newObj
}

/**
 * return uppercased string with type respect
 * @param str input string
 * @return string
 */
export const toTUpperCase = <T extends string>(str?: T) => {
  return (str?.toUpperCase() ?? "") as Uppercase<T>
}

/**
 * return strings with first char uppercased
 * @param strings List of strings
 * @param sep Separator char or string
 * @return string
 */
export const ucfirst = (str: string, onlyFirst = false): string => {
  return onlyFirst
    ? str.substring(0, 1).toUpperCase() + str.substring(1).toLowerCase()
    : str.replace(
        /([^ \-\n$]+)([ \-\n]|$)/g,
        (_s: string, m1: string, m2: string) =>
          m1.substring(0, 1).toUpperCase() + m1.substring(1).toLowerCase() + m2
      )
}

/**
 * return strings concatenated if not blank
 * @param strings List of strings
 * @param sep Separator char or string
 * @return string
 */
export const concat_strings = (
  strings: (string | number | undefined | null)[],
  sep?: string
): string => {
  return strings.filter(v => isNotNullNorUndefined(v) && isNotBlank(String(v))).join(sep ?? ",")
}

/**
 * Return an object with properties of base, with corresponding values merged from objects in other arguments
 * @param base Object <T> from where properties are defined
 * @param updateWith[] Objects Partial<T>[] to merge with base
 * @returns <T>
 */
export const updateObjectWith = <T extends Record<string | number | symbol, unknown>>(
  base: T,
  ...updateWith: { [K in keyof T]?: unknown }[]
) => {
  const r = { ...base } as Record<keyof T, unknown>
  const ks = objectKeys(base)
  for (const obj of updateWith) {
    for (const k of ks) {
      if (isKeyInObject(k, obj) && isNotNullNorUndefined(obj[k])) r[k] = obj[k]
    }
  }

  return r as Prettify<typeof r>
}

/**
 * Return an object with properties of to, and corresponding values of from
 * @param from Object from where properties are extracted
 * @param to Object of targeted type
 * @returns T
 */
export const extractFieldsFrom = <
  T extends UnknownRecord | Readonly<UnknownRecord>,
  U extends ReadonlyArray<keyof T> | Array<keyof T>,
>(
  from: T,
  to: U
) => {
  const r = {} as Pick<T, (typeof to)[number]>
  to.map(k => {
    if (k in from) r[k] = from[k]
  })

  return r as Prettify<typeof r>
}
export const extractPropertiesFrom = <
  T extends UnknownRecord | Readonly<UnknownRecord>,
  U extends Readonly<(keyof T)[]>,
>(
  base: T,
  pickProps: U
) => {
  const obj = {} as Partial<T>

  for (const prop in base) {
    if (pickProps.includes(prop)) obj[prop] = base[prop]
  }

  return obj as Prettify<Pick<T, U[number]>>
}

/**
 * Return an object omitting properties listed in omitProps
 * @param base Original object
 * @param omitProps Properties to remove
 * @returns Object with resulting properties
 */
export const excludePropertiesOf = <
  T extends UnknownRecord | Readonly<UnknownRecord>,
  U extends Readonly<(keyof T)[]>,
>(
  base: T,
  omitProps: U
) => {
  const obj = {} as Partial<T>

  for (const prop in base) {
    if (!omitProps.includes(prop)) obj[prop] = base[prop]
  }

  return obj as Prettify<Omit<T, U[number]>>
}

/**
 * Return an object containing all properties found in merged objects
 * @param base Object to merge with
 * @param objectsArray[] Array of Objects to merge with recordA
 * @returns Object with all properties
 */
export const mergeObjects = <
  TObjBase extends UnknownRecord,
  TObj2Merge extends Array<UnknownRecord> | ReadonlyArray<UnknownRecord>,
>(
  base: TObjBase,
  ...objectsArray: TObj2Merge
) => {
  return Object.assign({ ...base }, ...objectsArray) as Prettify<
    Merged<TObjBase, UnionToIntersection<TObj2Merge[number]>>
  >
}

/**
 * Return an object with similar keys but only with non blank properties.
 * Input only Record<string, string>
 * @param obj Object to convert
 * @param options List of prorperties to include and/or exclude
 * @returns { [k in keyof T]: string }
 */
export const extractNotBlankFieldsFromObject = <T extends Record<string, string>>(
  obj: T
): { [k in keyof T]: string } => {
  const r = {} as { [k in keyof T]: string }
  for (const [k, v] of Object.entries(obj)) {
    if (isNotBlank(v)) {
      r[k as keyof T] = v
    }
  }

  return r
}

/**
 * Return an object with similar keys but only with non null properties.
 * Input only Record<string, string>
 * @param obj Object to convert
 * @param options List of prorperties to include and/or exclude
 * @returns { [k in keyof T]: unknown }
 */
export const extractNotNullFieldsFromObject = <T extends Record<string, unknown>>(
  obj: T
): { [k in keyof T]: unknown } => {
  const r = {} as { [k in keyof T]: unknown }
  for (const [k, v] of Object.entries(obj)) {
    if (isNotNullNorUndefined(v)) {
      r[k as keyof T] = v
    }
  }

  return r
}

/**
 * Return any value converted to string
 * @param v Object to convert
 * @returns string
 */
export const convertPropertyToStringType = (v: unknown): string => {
  if (isNullOrUndefined(v)) {
    return ""
  }
  if (typeof v === "boolean") {
    return v === true ? "true" : "false"
  }
  if (typeof v === "number") {
    return v === 0 ? "" : String(v)
  }
  if (typeof v === "object") {
    if ("toString" in (v as Record<string | number | symbol, unknown>))
      return (v as Record<string | number | symbol, unknown>).toString()
    if ("length" in (v as string[])) return (v as string[]).join(",")
  }

  return String(v)
}

/**
 * Return an object with similar keys but properties are stringified
 * @param obj Object T to convert
 * @param options List of prorperties to include and/or exclude
 * @returns Record<keyof T, string> | Record<Exclude<keyof T, TExclude[number]>, string> | Record<Extract<keyof T, TExtract[number]>, string>
 */
export const convertPropertiesToStringType = <
  T extends UnknownRecord,
  TExclude extends Readonly<(keyof T)[]>,
  TExtract extends Readonly<(keyof T)[]>,
>(
  obj: T,
  options?: { exclude?: TExclude; extract?: TExtract }
) => {
  const exclArr = options?.exclude
  const extrArr = options?.extract

  if (isNotNullNorUndefined(exclArr)) {
    const keys = objectKeys(obj)
    const props = keys.filter(k => !exclArr.includes(k)) as Exclude<keyof T, TExclude[number]>[]
    const r = {} as Record<Exclude<keyof T, TExclude[number]>, string>
    for (const prop of props) r[prop] = convertPropertyToStringType(obj[prop])
    return r
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else if (isNotNullNorUndefined(extrArr)) {
    const keys = objectKeys(obj)
    const props = extrArr.filter(k => keys.includes(k)) as Extract<keyof T, TExtract[number]>[]
    const r = {} as Record<Extract<keyof T, TExtract[number]>, string>
    for (const prop of props) r[prop] = convertPropertyToStringType(obj[prop])
    return r
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else {
    const props = objectKeys(obj)
    const r = {} as Record<keyof T, string>
    for (const prop of props) r[prop] = convertPropertyToStringType(obj[prop])
    return r
  }
}
