import type { ComponentMultiStyleConfig } from "@chakra-ui/react"

export const Tabs: ComponentMultiStyleConfig = {
  parts: ["root", "tab", "tablist", "tabpanel"],
  baseStyle: {
    tab: {
      _selected: {
        color: "primary.500",
      },
    },
  },
  variants: {
    line: ({ orientation }) => ({
      tab: {
        _selected: {
          color: "primary.500",
        },
        ...(orientation === "vertical"
          ? {
              py: 4,
              pr: 2,
              pl: 6,
              _selected: {
                color: "primary.500",
                bg: "grey.100",
              },
            }
          : {}),
      },
      tablist: {
        ...(orientation === "vertical"
          ? {
              borderRight: "1px solid",
              borderRightColor: "grey.200",
              borderLeftColor: "transparent",
            }
          : {}),
      },
      tabpanel: {
        py: 6,
        px: 0,
      },
    }),
  },
}
