import { ErrorBoundary as SentryErrorBoundary, init as SentryInit } from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill"
import { createRoot } from "react-dom/client"

import App from "./App"
import "./index.scss"
import { Error as MyError } from "./pages/Error"
import { enableHuddleCommentModule } from "./utils/huddleCommentModule/huddleCommentModule"

// import countryFlagEmojiFont from "~/node_modules/country-flag-emoji-polyfill/dist/TwemojiCountryFlags.woff2"

polyfillCountryFlagEmojis("Twemoji Country Flags")

if (import.meta.env.PROD) {
  enableHuddleCommentModule()

  SentryInit({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: __COMMIT_HASH__,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: [new URL(import.meta.env.VITE_API_BASE_URL).hostname],
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  })
}

createRoot(document.getElementById("root") as HTMLElement).render(
  <SentryErrorBoundary fallback={MyError}>
    <App />
  </SentryErrorBoundary>
)
