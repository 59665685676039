import type { ComponentMultiStyleConfig } from "@chakra-ui/react"

export const Input: ComponentMultiStyleConfig = {
  parts: ["field", "addon"],
  variants: {
    searchbar: {
      field: {
        borderRadius: 0,
        borderColor: "grey.200",
        _placeholder: {
          color: "grey.200",
        },
        _active: {
          borderColor: "grey.200",
        },
        _disabled: {
          backgroundColor: "grey.200",
          color: "grey.600",
          _placeholder: {
            color: "grey.600",
          },
        },
      },
    },
    outline: {
      field: {
        borderRadius: 0,
        backgroundColor: "white",
        border: "1px solid",
        borderColor: "grey.200",
      },
    },
  },
  sizes: {
    md: {
      field: {
        p: "4",
        textStyle: "p2",
        height: "full",
      },
    },
    sm: {
      field: {
        py: "2",
        px: "4",
        textStyle: "p3",
        height: "fit-content",
      },
    },
  },
  defaultProps: {
    size: "sm",
    variant: "outline",
  },
}
