import type { ComponentMultiStyleConfig } from "@chakra-ui/react"

export const Radio: ComponentMultiStyleConfig = {
  parts: ["label", "container", "control"],
  baseStyle: {
    control: {
      borderColor: "grey.200",
      bgColor: "grey.100",
    },
  },
  sizes: {
    sm: {
      field: {
        textStyle: "p3",
      },
    },
  },
  defaultProps: {
    colorScheme: "primary",
  },
}
