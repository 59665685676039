import FrenchAccessLevels from "./fr-access-levels.json"
import FrenchCountries from "./fr-countries.json"
import FrenchJobTitles from "./fr-job-titles.json"
import FrenchLanguages from "./fr-languages.json"
import FrenchNationalities from "./fr-nationalities.json"
import FrenchRefLabels from "./fr-ref-labels.json"
import FrenchStatus from "./fr-status.json"
import FrenchTypes from "./fr-type.json"
import French from "./fr.json"

export const frenchMessages = {
  ...French,
  ...FrenchCountries,
  ...FrenchJobTitles,
  ...FrenchLanguages,
  ...FrenchNationalities,
  ...FrenchRefLabels,
  ...FrenchStatus,
  ...FrenchTypes,
  ...FrenchAccessLevels,
}
