import { Box, Flex } from "@chakra-ui/react"

import Menu from "./Menu"

export const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Flex height="100%">
      <Menu />
      <Box flexGrow={1} overflow="auto" backgroundColor="grey.100">
        {children}
      </Box>
    </Flex>
  )
}
