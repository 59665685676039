import type { ComponentMultiStyleConfig } from "@chakra-ui/react"

export const NumberInput: ComponentMultiStyleConfig = {
  parts: ["root", "field", "addon"],
  variants: {
    outline: {
      field: {
        borderRadius: 0,
        backgroundColor: "white",
        border: "1px solid",
        borderColor: "grey.200",
      },
      root: {
        textAlign: "right",
        bg: "grey.200",
      },
    },
  },
  sizes: {
    md: {
      field: {
        p: "4",
        textStyle: "p2",
        height: "full",
      },
    },
    sm: {
      field: {
        py: "2",
        px: "4",
        textStyle: "p3",
        height: "fit-content",
        w: "6rem",
        textAlign: "right",
      },
    },
  },
  defaultProps: {
    size: "sm",
    variant: "outline",
  },
}
