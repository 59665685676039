import type { ComponentMultiStyleConfig } from "@chakra-ui/react"

export const Select: ComponentMultiStyleConfig = {
  parts: ["icon", "field"],
  variants: {
    barnes: {
      field: {
        borderRadius: 0,
        bg: "white",
        border: "1px solid",
        borderColor: "grey.200",
        textStyle: "p3",
        _invalid: {
          borderColor: "red.500",
          bgColor: "red.100",
          color: "red.600",
        },
      },
      icon: {
        color: "primary.500",
      },
    },
  },
  sizes: {
    default: {
      field: { py: 2, pl: 4, pr: 9 },
    },
  },
  defaultProps: {
    size: "default",
    variant: "barnes",
  },
}
