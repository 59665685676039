/* eslint-disable */

export const enableHuddleCommentModule = () =>
  ((d, t, g, k) => {
    var ph = d.createElement(t),
      s = d.getElementsByTagName(t)[0],
      t = new URLSearchParams(window.location.search).get(k)
    t && localStorage.setItem(k, t)
    t = localStorage.getItem(k)
    ph.type = "text/javascript"
    ph.async = true
    ph.defer = true
    ph.charset = "UTF-8"
    ph.src = g + "&v=" + new Date().getTime()
    ph.src += t ? "&" + k + "=" + t : ""
    s.parentNode.insertBefore(ph, s)
  })(
    document,
    "script",
    "//hubble.kanbios.fr/?p=2338&ph_apikey=05de7fbfcae55d780163699c374a5b9a",
    "ph_access_token"
  )
