import { Box, Flex, type createIcon } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"

import { P3 } from "../Typographies"
import styles from "./MenuItem.module.scss"

interface Props {
  icon: ReturnType<typeof createIcon>
  label: string
  path: string
  exact?: boolean
}

export default function MenuItem({ icon: Icon, label, path, exact }: Props) {
  return (
    <NavLink to={path} className={styles.navLink} end={exact}>
      {({ isActive }) => (
        <>
          <Box w="1" h="100%" bg={isActive ? "white" : undefined} flexShrink={0} />
          <Flex
            color="white"
            p={1}
            flexDir="column"
            gap="2px"
            align="center"
            opacity={isActive ? 1 : 0.5}
          >
            <Icon h="6" w="6" />
            <P3 textAlign="center">{label}</P3>
          </Flex>
          <Box w="1" h="100%" />
        </>
      )}
    </NavLink>
  )
}
