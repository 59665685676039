import type { ComponentSingleStyleConfig } from "@chakra-ui/react"

export const Textarea: ComponentSingleStyleConfig = {
  variants: {
    outline: {
      borderRadius: 0,
      borderColor: "grey.200",
      backgroundColor: "white",
      _placeholder: {
        color: "grey.300",
      },
    },
  },
}
