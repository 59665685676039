import { useUpdateEffect } from "@react-hookz/web"
import { configureScope } from "@sentry/react"
import jwtDecode from "jwt-decode"
import { createContext, useContext, useState } from "react"

import { useAccessToken } from "@/hooks/useAccessToken"
import { removeFieldsFromObject } from "@/utils/ts-utils"

import { AuthenticatedUser } from "./AuthenticatedUser"
import type { AuthenticatedUserFromJWT } from "./AuthenticatedUserFromJWT"

interface IAuthenticatedUser {
  user?: AuthenticatedUser
}

export const authenticatedUserContext = createContext<IAuthenticatedUser>({})

export default function AuthenticatedUserContextProvider(props: React.PropsWithChildren) {
  const { accessToken } = useAccessToken()

  const authenticatedUser =
    accessToken !== undefined ? jwtDecode<AuthenticatedUserFromJWT>(accessToken) : undefined

  const [user, setUser] = useState<AuthenticatedUser | undefined>(() =>
    AuthenticatedUser.createFromJWTPayload(authenticatedUser)
  )

  const stringifiedUsefulFields =
    authenticatedUser !== undefined
      ? JSON.stringify(removeFieldsFromObject(authenticatedUser, ["exp", "iat"]))
      : undefined

  useUpdateEffect(() => {
    setUser(AuthenticatedUser.createFromJWTPayload(authenticatedUser))
    configureScope(scope => {
      scope.setUser(authenticatedUser ? { username: authenticatedUser.username } : null)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedUsefulFields]) // update user only if useful fields change

  return (
    <authenticatedUserContext.Provider value={{ user }}>
      {props.children}
    </authenticatedUserContext.Provider>
  )
}

export const useAuthenticatedUserContext = () => useContext(authenticatedUserContext)
