import { useLocalStorageValue, useSessionStorageValue } from "@react-hookz/web"

import { ACCESS_TOKEN_KEY } from "@/utils/sessionStorageKeys"

const useStorageValue = import.meta.env.VITE_STORE_TOKEN_IN_LOCAL_STORAGE
  ? useLocalStorageValue
  : useSessionStorageValue

export const useAccessToken = () => {
  const { value, set, remove } = useStorageValue<{ token?: string }>(ACCESS_TOKEN_KEY)

  const setAccessToken = (token: string) => set({ token })

  return { accessToken: value?.token, setAccessToken, removeAccessToken: remove }
}
