import { extendTheme } from "@chakra-ui/react"

import { Button } from "./components-style-overrides/button-definition"
import { Checkbox } from "./components-style-overrides/checkbox-definition"
import { Divider } from "./components-style-overrides/divider-definition"
import { FormLabel } from "./components-style-overrides/form-label-definition"
import { Input } from "./components-style-overrides/input-definition"
import { Modal } from "./components-style-overrides/modal-definition"
import { NumberInput } from "./components-style-overrides/number-definition"
import { Radio } from "./components-style-overrides/radio-definition"
import { Select } from "./components-style-overrides/select-definition"
import { Switch } from "./components-style-overrides/switch-definition"
import { Table } from "./components-style-overrides/table-definition"
import { Tabs } from "./components-style-overrides/tabs-definition"
import { Tag } from "./components-style-overrides/tag-definition"
import { Textarea } from "./components-style-overrides/textarea-definition"
import { Tooltip } from "./components-style-overrides/tooltip-definition"
import { colors, fontWeights, fonts, shadows, textStyles } from "./values"

export const theme = extendTheme({
  colors,
  fonts,
  fontWeights,
  textStyles,
  shadows,
  components: {
    Button,
    Divider,
    Tag,
    Input,
    NumberInput,
    Textarea,
    Tabs,
    FormLabel,
    Modal,
    Radio,
    Select,
    Switch,
    Table,
    Tooltip,
    Checkbox,
  },
})
