import type { ComponentSingleStyleConfig } from "@chakra-ui/react"

import { sourceSansProFontString } from "../values"

export const Button: ComponentSingleStyleConfig = {
  baseStyle: {
    fontWeight: "normal",
    borderRadius: "0",
    fontFamily: sourceSansProFontString,
    fontSize: "16px",
    lineHeight: "20px",
  },
  sizes: {
    sm: {
      py: "2",
      px: "6",
      height: "fit-content",
    },
    md: {
      py: "3.5",
      px: "6",
      height: "fit-content",
    },
    round: {
      py: "2",
      px: "4",
      borderRadius: "full",
    },
  },
  variants: {
    outline: {
      outline: "1px solid",
      outlineColor: "primary.500",
      outlineOffset: "-1px",
      border: "none",
      boxSizing: "border-box",
      color: "primary.600",
      bg: "white",
      _hover: {
        bg: "primary.100",
      },
      _active: {
        bg: "primary.100",
      },
      _disabled: {
        outlineColor: "gray.300",
        color: "gray.500",
        opacity: 1,
      },
    },
    solid: {
      bg: "primary.500",
      color: "white",
      _hover: {
        bg: "primary.600",
      },
      _active: {
        bg: "primary.500",
      },
    },
    transparent: {
      bg: "transparent",
      color: "primary.500",
      _hover: {
        bg: "transparent",
        color: "primary.600",
      },
      _active: {
        bg: "transparent",
      },
    },
    link: {
      bg: "transparent",
      fontWeight: "600px",
      fontSize: "14px",
      lineHeight: "18px",
      textDecor: "underline",
      color: "grey.600",
      _hover: {
        bg: "transparent",
      },
      _active: {
        bg: "transparent",
      },
    },
    selectable: {
      border: "1px solid",
      borderColor: "grey.300",
      bg: "white",
      color: "grey.600",
    },
    selected: {
      bg: "red.600",
      color: "white",
      border: "1px solid",
      borderColor: "red.600",
    },
  },
  defaultProps: {
    size: "md",
    variant: "solid",
  },
}
