import type { ComponentMultiStyleConfig } from "@chakra-ui/react"

export const Checkbox: ComponentMultiStyleConfig = {
  parts: ["icon", "container", "control", "label"],
  baseStyle: {
    container: {
      borderColor: "grey.500",
    },
  },
  sizes: {
    sm: {
      field: {
        textStyle: "p3",
      },
    },
  },
  defaultProps: {
    colorScheme: "primary",
  },
}
