import type { Location } from "react-router-dom"

export type LoginLocationState = { location: Location } | null | undefined

export enum PATHS {
  DASHBOARD = "/",
  AUTH = "/auth",
  CLIENTS = "/clients",
  CUSTOMERS = "/customers",
  PROPERTIES = "/properties",
  PROPERTIES_OLD = "/property-old",
  PROPERTY_OFFERS = "/property-offers",
  INTER_BU = "/inter-bu",
  RESOURCES = "/resources",
  USERS = "/resources/users",
  COLLABORATORS = "/resources/collaborators",
  HELP = "/help",
  NETWORK = "/network",
  PARAMETERS = "/parameters",
  ADMIN = "/admin",
}

export enum CLIENT_PATHS {
  GENERAL = "",
  INHERITANCE = "/inheritance",
  HISTORY = "/history",
  COMMENTS = "/comments",
  NETWORK = "/network",
  LOGS = "/logs",
}

export enum INTER_BU_PATHS {
  SHARED = "/shared",
  RECEIVED = "/received",
  SENT = "/sent",
}

export enum USER_PATHS {
  GENERAL = "",
  HISTORY = "/history",
  LOGS = "/logs",
}

export enum ADMIN_PATHS {
  SUBSCRIPTIONS = "/subscriptions",
  CIRCLES = "/circles",
  HOBBIES = "/hobbies",
  EVENTS = "/events",
}

export enum NETWORK_PATHS {
  COMPANIES = "/companies",
  COMPANY = "/company",
  AGENCIES = "/agencies",
  AGENCY = "/agency",
  CLUSTERS = "/clusters",
  COLLABORATORS = "/collaborators",
  REF_LABELS = "/ref_labels",
  PROPERTIES = "/properties",
  PROJECTS = "/projects",
  PROJECTS_OFFER = "/projects_offer",
  PROJECTS_REQUEST = "/projects_request",
  PEOPLE = "/people",
  SYNCHRO = "/synchro",
  USERS = "/users",
}

export enum PROJECT_PATHS {
  GENERAL = "",
  OFFER = "/offer",
  REQUEST = "/request",
  HISTORY = "/history",
  ADVERTISER = "/advertiser",
  COMMENTS = "/comments",
}

export enum PROPERTY_PATHS {
  GENERAL = "",
  HISTORY = "/history",
  COMMENTS = "/comments",
  BROADCASTING = "/broadcasting",
  LOGS = "/logs",
}

export enum COMPANY_PATHS {
  GENERAL = "",
  ARCHIVES = "/archives",
  AGENCIES = "/agencies",
  CLUSTERS = "/clusters",
  COLLABORATORS = "/collaborators",
  SETTINGS = "/settings",
}

export enum COLLABORATOR_PATHS {
  GENERAL = "",
  ARCHIVES = "/archives",
  SETTINGS = "/settings",
  COMPANIES = "/companies",
  AGENCIES = "/agencies",
  CLUSTERS = "/clusters",
  PEOPLE = "/people",
  PROJECTS = "/projects",
  PROJECT_OFFERS = "/project_offers",
  PROJECT_SEARCH = "/project_search",
}

export enum CLUSTER_PATHS {
  GENERAL = "",
  ARCHIVES = "/archives",
  COMPANIES = "/companies",
  AGENCIES = "/agencies",
  COLLABORATORS = "/collaborators",
  PROJECTS = "/projects",
  PROJECT_OFFERS = "/project_offers",
  PROJECT_SEARCH = "/project_search",
  PEOPLES = "/peoples",
  SETTINGS = "/settings",
}

export enum AGENCY_PATHS {
  GENERAL = "",
  ARCHIVES = "/archives",
  COMPANIES = "/companies",
  CLUSTERS = "/clusters",
  COLLABORATORS = "/collaborators",
  SETTINGS = "/settings",
}

export enum PEOPLE_PATHS {
  GENERAL = "",
  COLLABORATORS = "/collaborators",
  PROJECT_OFFERS = "/project_offers",
  PROJECT_SEARCH = "/project_search",
  PROPERTIES = "/properties",
  PROJECTS_PROPERTIES = "/projects_properties",
  COMMENTS = "/comments",
  CLUSTERS = "/clusters",
  SETTINGS = "/settings",
}

export enum PARAMETERS_PATHS {
  DISPLAY = "/display",
  PROFIL = "/profil",
  ACCOUNT = "/account",
  MAIL_SIGN = "/mail_signature",
}

export enum AUTH_PATHS {
  LOGIN = "/login",
  LOGOUT = "/logout",
  REQUEST_PWD = "/request_pwd",
  RENEW_PWD = "/renew_pwd",
}
