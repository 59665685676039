import { Box, Center, Spinner, keyframes } from "@chakra-ui/react"

const appear = keyframes`
  to {
    transform: unset;
  }
`

type Props = {
  noAppearDelay?: boolean
}

export const FullLoader: React.FC<Props> = ({ noAppearDelay }) => {
  return (
    <Center boxSize="full" minH={20} data-testid="full-loader">
      <Box
        animation={`${appear} 300ms ${noAppearDelay ? "0s" : "300ms"} ease-out forwards`}
        transform="scale(0)"
      >
        <Spinner size="xl" />
      </Box>
    </Center>
  )
}
