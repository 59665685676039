import axios, { type AxiosError, type AxiosRequestConfig } from "axios"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { useAccessToken } from "@/hooks/useAccessToken"
import { AUTH_PATHS, type LoginLocationState, PATHS } from "@/routing/paths"
import { isAccessTokenExpired } from "@/utils/token"

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: { Accept: "application/ld+json, application/json" },
})

export const customMutator = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => axiosInstance({ ...config, ...options }).then(({ data }) => data as T)

const urlDoesNotNeedAccessToken = (url?: string) =>
  [
    undefined,
    "/api/login_check",
    "/api/token/refresh",
    "/api/reset/request",
    "/api/reset/password",
  ].includes(url)

export const useSendLogoutRequest = () => {
  const navigate = useNavigate()
  const { removeAccessToken } = useAccessToken()

  const sendLogoutRequest = () => {
    return axiosInstance
      .get<{ token: string }>("/api/token/invalidate", { withCredentials: true }) // no credentials needed
      .then(() => {
        removeAccessToken()
        navigate(PATHS.AUTH + AUTH_PATHS.LOGIN)
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 401) navigate(PATHS.AUTH + AUTH_PATHS.LOGIN)
        throw error
      })
  }

  return { sendLogoutRequest }
}

let refreshTokenRequest: Promise<string> | null = null

export const useSendRefreshTokenRequest = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { setAccessToken, removeAccessToken } = useAccessToken()

  const sendRefreshTokenRequest = () => {
    return axiosInstance
      .get<{ token: string }>("/api/token/refresh", { withCredentials: true }) // withCredentials to allow cookies to be sent
      .then(({ data: { token } }) => {
        setAccessToken(token)
        refreshTokenRequest = null

        return token
      })
      .catch((error: AxiosError) => {
        removeAccessToken()
        refreshTokenRequest = null
        const state: LoginLocationState = { location }
        if (error.response?.status === 401) navigate(PATHS.AUTH + AUTH_PATHS.LOGIN, { state })
        throw error
      })
  }

  return { sendRefreshTokenRequest }
}

export const useRegisterAxiosInterceptors = () => {
  const [hasAxiosBeenInitializedOnce, setHasAxiosBeenInitializedOnce] = useState(false)
  const { accessToken } = useAccessToken()
  const { sendRefreshTokenRequest } = useSendRefreshTokenRequest()

  useEffect(() => {
    const requestInterceptorId = axiosInstance.interceptors.request.use(
      async requestConfig => {
        if (urlDoesNotNeedAccessToken(requestConfig.url)) return requestConfig

        let accessTokenToUse = accessToken

        if (accessTokenToUse === undefined || isAccessTokenExpired(accessTokenToUse)) {
          if (refreshTokenRequest === null) refreshTokenRequest = sendRefreshTokenRequest()
          accessTokenToUse = await refreshTokenRequest
        }

        requestConfig.headers.Authorization = `Bearer ${accessTokenToUse}`

        return requestConfig
      },
      error => Promise.reject(error)
    )

    const responseInterceptorId = axiosInstance.interceptors.response.use(
      response => response,
      async error => {
        // eslint-disable-next-line
        if (urlDoesNotNeedAccessToken(error.config?.url)) return Promise.reject(error)

        // eslint-disable-next-line
        if (error.config && error.response?.status === 401) {
          if (refreshTokenRequest === null) refreshTokenRequest = sendRefreshTokenRequest()
          await refreshTokenRequest

          // eslint-disable-next-line
          return axiosInstance(error.config)
        }

        return Promise.reject(error)
      }
    )

    setHasAxiosBeenInitializedOnce(true)

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptorId)
      axiosInstance.interceptors.response.eject(responseInterceptorId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, sendRefreshTokenRequest]) // TODO: check if not introducing a bug

  return { hasAxiosBeenInitializedOnce }
}
